import { WidgetBuilder, FlowEditorSDK, FlowAPI } from '@wix/yoshi-flow-editor';
import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { configureWidgetTextDesignGfpp } from '../../utils/manifestUtils';
import { ProjectHeaderWidgetTextComponentIds } from './config/constants';
import { setConfigureConnectedComponents } from './config/utils';

export const getWidgetManifest: GetWidgetManifestFn = (
  controllerBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  flowAPI: FlowAPI,
) => {
  const { t } = flowAPI.translations;
  const { isEditorX } = flowAPI.environment;
  controllerBuilder.set({
    displayName: t('Widget_Name_Project_Header_Widget'),
    nickname: 'projectHeaderWidget',
  });
  setConfigureConnectedComponents({ controllerBuilder, editorSDK, flowAPI });
  if (!isEditorX) {
    configureWidgetTextDesignGfpp({
      controllerBuilder,
      editorSDK,
      t,
      textComponentIds: ProjectHeaderWidgetTextComponentIds,
    });
  }
};
