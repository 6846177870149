import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { WidgetBuilder, FlowEditorSDK, FlowAPI } from '@wix/yoshi-flow-editor';
import { defaultHelpId } from '../../constants';
import { openDashboardProjects } from '../../utils/dashboardUtils';
import { FeaturedCollectionWidgetTabsArray } from './config/constants';
import { setWidgetDesignPanel } from '../../utils/manifestUtils';

const setDesktopGFPP = (
  controllerBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  t,
) => {
  controllerBuilder
    .gfpp()
    .set('mainAction1', {
      label: t('Featured_Collection_Widget_Gfpp_Main_Action_1'),
      onClick: openDashboardProjects(editorSDK),
    })
    .set('mainAction2', {
      label: t('Featured_Collection_Widget_Gfpp_Main_Action_2'),
      actionId: 'openFeaturedCollectionPanel',
    })
    .set('layout', { behavior: 'HIDE' })
    .set('connect', { behavior: 'HIDE' })
    .set('animation', { behavior: 'HIDE' })
    .set('add', { actionId: 'openElementsPanel' })
    .set('help', { id: defaultHelpId });
};
const setMobileGFPP = (
  controllerBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  t,
) => {
  controllerBuilder
    .gfpp('mobile')
    .set('mainAction1', {
      label: t('Featured_Collection_Widget_Gfpp_Main_Action_1'),
      onClick: openDashboardProjects(editorSDK),
    })
    .set('mainAction2', {
      label: t('Featured_Collection_Widget_Gfpp_Main_Action_2'),
      actionId: 'openFeaturedCollectionPanel',
    })
    .set('presets', { behavior: 'HIDE' })
    .set('layout', { behavior: 'HIDE' })
    .set('animation', { behavior: 'HIDE' })
    .set('help', { id: defaultHelpId });
};

export const getWidgetManifest: GetWidgetManifestFn = (
  controllerBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  flowAPI: FlowAPI,
) => {
  const { t } = flowAPI.translations;
  const { isEditorX } = flowAPI.environment;
  // @ts-expect-error
  const isStudio = self?.commonConfig?.brand === 'studio';

  controllerBuilder.set({
    displayName: t('Widget_Name_Featured_Collection_Widget'),
    nickname: 'FeaturedCollectionWidget',
  });
  controllerBuilder.behavior().set({
    duplicatable: true,
    toggleShowOnAllPagesEnabled: false,
  });
  setDesktopGFPP(controllerBuilder, editorSDK, t);
  setMobileGFPP(controllerBuilder, editorSDK, t);
  setWidgetDesignPanel({
    controllerBuilder,
    t,
    presetsTabArray: FeaturedCollectionWidgetTabsArray,
    title: t('Featured_Collection_Widget_Design_Panel_Title'),
    isEditorX,
    isStudio,
  });
};
