export const getFeaturedCollectionElementsData = (
  t: any,
  numOfSelectedCollections: number,
) => {
  const COMPS_DATA_ONE_COLLECTION = {
    portfolioTitle: {
      role: 'collectionTitle',
      label: t('Portfolio_Elements_Panel_Title'),
    },
    description: {
      role: 'collectionDescription',
      label: t('Portfolio_Elements_Panel_Description'),
    },
    collectionGallery: {
      role: 'collectionGallery',
      label: t('Portfolio_Elements_Panel_Gallery'),
    },
  };
  const COMPS_DATA_MULTI_COLLECTIONS = {
    collectionsGallery: {
      role: 'collectionsGallery',
      label: t('Portfolio_Elements_Panel_Gallery'),
    },
  };

  const COLLECTIONS_IDS = {
    info: t('Portfolio_Elements_Panel_Category_Info'),
    gallery: t('Portfolio_Elements_Panel_Category_Media'),
  };

  const categoriesData =
    numOfSelectedCollections > 1
      ? [
          {
            id: COLLECTIONS_IDS.gallery,
            title: COLLECTIONS_IDS.gallery,
          },
        ]
      : [
          {
            id: COLLECTIONS_IDS.info,
            title: COLLECTIONS_IDS.info,
          },
          {
            id: COLLECTIONS_IDS.gallery,
            title: COLLECTIONS_IDS.gallery,
          },
        ];
  const SingleCollectionElementsData = [
    {
      label: COMPS_DATA_ONE_COLLECTION.portfolioTitle.label,
      identifier: { role: COMPS_DATA_ONE_COLLECTION.portfolioTitle.role },
      categoryId: COLLECTIONS_IDS.info,
      index: 0,
    },
    {
      label: COMPS_DATA_ONE_COLLECTION.description.label,
      identifier: { role: COMPS_DATA_ONE_COLLECTION.description.role },
      categoryId: COLLECTIONS_IDS.info,
      index: 1,
    },
    {
      label: COMPS_DATA_ONE_COLLECTION.collectionGallery.label,
      identifier: {
        role: COMPS_DATA_ONE_COLLECTION.collectionGallery.role,
      },
      categoryId: COLLECTIONS_IDS.gallery,
      index: 2,
    },
  ];
  const MultiCollectionsElementsData = [
    {
      label: COMPS_DATA_MULTI_COLLECTIONS.collectionsGallery.label,
      identifier: {
        role: COMPS_DATA_MULTI_COLLECTIONS.collectionsGallery.role,
      },
      categoryId: COLLECTIONS_IDS.gallery,
      index: 0,
    },
  ];
  const elementsData =
    numOfSelectedCollections > 1
      ? MultiCollectionsElementsData
      : SingleCollectionElementsData;

  return {
    categoriesData,
    elementsData,
  };
};
