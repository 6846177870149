import { getPortfolioPageStateSettings } from './configurePages';
import PortfolioComponentJson from '../../components/portfolioPageWidget/.component.json';
import { getRole } from '../../utils/manifestUtils';
import { PortfolioPageWidgetComponentIds } from '../../components/portfolioPageWidget/config/constants';
import { GalleryLayoutPanelConfigurations } from '../../components/projectHeaderWidget/config/constants';

const removeNativeGalleryRationInLayoutPanel = (appManifest) => {
  const controllerData = [
    {
      id: 'd90652a2-f5a1-4c7c-84c4-d4cdcc41f130-os5sm',
      galleryId: 'collectionGallery',
    },
    {
      id: 'd90652a2-f5a1-4c7c-84c4-d4cdcc41f130-itb6f',
      galleryId: 'collectionsGallery',
    },
    {
      id: 'd90652a2-f5a1-4c7c-84c4-d4cdcc41f130-ojo5l',
      galleryId: 'projectGallery1',
    },
  ];
  controllerData.forEach((controller) => {
    appManifest.controllersStageData[controller.id].default.connections[
      controller.galleryId
    ].panel = {
      ...appManifest.controllersStageData[controller.id].default.connections[
        controller.galleryId
      ].panel,
      layout: {
        controls: GalleryLayoutPanelConfigurations,
      },
    };
  });
};

const hidePortfolioPageWidgetElementsGFPP = (appManifest) => {
  appManifest.controllersStageData[
    PortfolioComponentJson.controllerId
  ].default.gfpp.desktop.iconButtons.add = { behavior: 'HIDE' };
};
const hidePortfolioPageWidgetPresetPanelGFPP = (appManifest) => {
  appManifest.controllersStageData[
    PortfolioComponentJson.controllerId
  ].default.gfpp.desktop.widgetPresets = null;
};
const setPortfolioPageWidgetDesignTab = (appManifest, t) => {
  appManifest.controllersStageData[
    PortfolioComponentJson.controllerId
  ].default.gfpp.desktop.widgetDesign.tabs = [
    // {
    //   label: t('Portfolio_Design_Panel_Projects_Gallery'),
    //   groups: {
    //     roles: [CollectionsGalleryWidgetComponentIds.GalleyWidget].map(getRole),
    //   },
    // },
    {
      label: t('Portfolio_Design_Panel_Page_Background'),
      groups: {
        roles: [PortfolioPageWidgetComponentIds.Root].map(getRole),
      },
    },
  ];
};

const removePresets = (appManifest) => {
  appManifest.controllersStageData[
    PortfolioComponentJson.controllerId
  ].default.gfpp.desktop.widgetDesign.presets = undefined;
};

export const setCustomManifest = (
  appManifest: any,
  t: any,
  collectionsLength: number,
) => {
  appManifest.pages.pageSettings.Portfolio = getPortfolioPageStateSettings(t);
  if (collectionsLength > 1) {
    hidePortfolioPageWidgetElementsGFPP(appManifest);
    hidePortfolioPageWidgetPresetPanelGFPP(appManifest);
    setPortfolioPageWidgetDesignTab(appManifest, t);
    removePresets(appManifest);
  }
  removeNativeGalleryRationInLayoutPanel(appManifest);
};
