export const getPortfolioElementsData = (t: any) => {
  const COMPS_DATA = {
    portfolioTitle: {
      role: 'collectionTitle',
      label: t('Portfolio_Elements_Panel_Title'),
    },
    description: {
      role: 'collectionDescription',
      label: t('Portfolio_Elements_Panel_Description'),
    },
    collectionGallery: {
      role: 'collectionGallery',
      label: t('Portfolio_Elements_Panel_Gallery'),
    },
  };

  const COLLECTIONS_IDS = {
    info: t('Portfolio_Elements_Panel_Category_Info'),
    gallery: t('Portfolio_Elements_Panel_Category_Media'),
  };

  const categoriesData = [
    {
      id: COLLECTIONS_IDS.info,
      title: COLLECTIONS_IDS.info,
    },
    {
      id: COLLECTIONS_IDS.gallery,
      title: COLLECTIONS_IDS.gallery,
    },
  ];

  const elementsData = [
    {
      label: COMPS_DATA.portfolioTitle.label,
      identifier: { role: COMPS_DATA.portfolioTitle.role },
      categoryId: COLLECTIONS_IDS.info,
      index: 0,
    },
    {
      label: COMPS_DATA.description.label,
      identifier: { role: COMPS_DATA.description.role },
      categoryId: COLLECTIONS_IDS.info,
      index: 1,
    },
    {
      label: COMPS_DATA.collectionGallery.label,
      identifier: { role: COMPS_DATA.collectionGallery.role },
      categoryId: COLLECTIONS_IDS.gallery,
      index: 2,
    },
  ];

  return {
    categoriesData,
    elementsData,
  };
};
