import { handleFirstInstall } from './first-install/handleFirstInstall';
import { setPortfolioPagesStates } from '../utils/pagesUtils';
import { editorAppEventListener } from './editorAppEventListener';
import {
  EditorScriptFlowAPI,
  FlowEditorSDK,
  FlowPlatformOptions,
} from '@wix/yoshi-flow-editor';
import { AppDefId } from '../constants';

const actionName = 'portfolio-first-install';
export const onEditorReady = async (
  editorSDK: FlowEditorSDK,
  appDefId: string,
  options: FlowPlatformOptions,
  flowAPI: EditorScriptFlowAPI,
): Promise<void> => {
  if (options.firstInstall) {
    try {
      await editorSDK.document.transactions.runAndWaitForApproval(
        '',
        async () => {
          flowAPI.fedops.interactionStarted(actionName);

          const areAppPagesInstalled =
            await editorSDK.pages.getApplicationPages('');
          if (areAppPagesInstalled.length >= 3) {
            await editorSDK.editor.deeplink.show('', {
              type: 'pagesPanel',
              params: [AppDefId],
            });
          } else {
            await handleFirstInstall(editorSDK, appDefId, options, flowAPI);
          }

          flowAPI.fedops.interactionEnded(actionName);
        },
      );
    } catch (e: any) {
      flowAPI.errorMonitor?.captureException(e, { tags: { actionName } });
      editorSDK.application.uninstall('', { openConfirmation: false });
      console.error('handleFirstInstall failed', { e });
      throw e;
    }
  }
  setPortfolioPagesStates(editorSDK);
  editorAppEventListener(editorSDK, flowAPI, options.origin.type as any);
};
