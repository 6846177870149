export const getProjectElementsData = (t: any) => {
  const COMPS_DATA = {
    projectTitle: {
      role: 'projectTitle',
      label: t('Project_Elements_Panel_Title'),
    },
    description: {
      role: 'projectDescription',
      label: t('Project_Elements_Panel_Description'),
    },
    detailsLabel: {
      role: 'detailLabel',
      label: t('Project_Elements_Panel_Details_Label'),
    },
    detailsText: {
      role: 'detailText',
      label: t('Project_Elements_Panel_Details_Text'),
    },
    projectHeroImage: {
      role: 'proGallery1',
      label: t('Project_Elements_Panel_Hero_Image'),
    },
    projectNavigation: {
      role: 'navigationWidget1',
      label: t('Project_Elements_Panel_Navigation'),
    },
    projectGallery: {
      role: 'projectGallery1',
      label: t('Project_Elements_Panel_Gallery'),
    },
  };

  const COLLECTIONS_IDS = {
    info: t('Project_Elements_Panel_Category_Info'),
    gallery: t('Project_Elements_Panel_Category_Gallery'),
    details: t('Project_Elements_Panel_Category_Details'),
    navigation: t('Project_Elements_Panel_Category_Navigation'),
  };

  const categoriesData = [
    {
      id: COLLECTIONS_IDS.info,
      title: COLLECTIONS_IDS.info,
    },
    {
      id: COLLECTIONS_IDS.details,
      title: COLLECTIONS_IDS.details,
    },
    {
      id: COLLECTIONS_IDS.gallery,
      title: COLLECTIONS_IDS.gallery,
    },
    {
      id: COLLECTIONS_IDS.navigation,
      title: COLLECTIONS_IDS.navigation,
    },
  ];

  const elementsData = [
    {
      label: COMPS_DATA.projectTitle.label,
      identifier: { role: COMPS_DATA.projectTitle.role },
      categoryId: COLLECTIONS_IDS.info,
      index: 0,
    },
    {
      label: COMPS_DATA.description.label,
      identifier: { role: COMPS_DATA.description.role },
      categoryId: COLLECTIONS_IDS.info,
      index: 1,
    },
    {
      label: COMPS_DATA.detailsLabel.label,
      identifier: { role: COMPS_DATA.detailsLabel.role },
      categoryId: COLLECTIONS_IDS.details,
      index: 3,
    },
    {
      label: COMPS_DATA.detailsText.label,
      identifier: { role: COMPS_DATA.detailsText.role },
      categoryId: COLLECTIONS_IDS.details,
      index: 4,
    },
    {
      label: COMPS_DATA.projectHeroImage.label,
      identifier: { role: COMPS_DATA.projectHeroImage.role },
      categoryId: COLLECTIONS_IDS.gallery,
      index: 5,
    },
    {
      label: COMPS_DATA.projectGallery.label,
      identifier: { role: COMPS_DATA.projectGallery.role },
      categoryId: COLLECTIONS_IDS.gallery,
      index: 6,
    },
    {
      label: COMPS_DATA.projectNavigation.label,
      identifier: { role: COMPS_DATA.projectNavigation.role },
      categoryId: COLLECTIONS_IDS.navigation,
      index: 7,
    },
  ];

  return {
    categoriesData,
    elementsData,
  };
};
