import { WidgetBuilder, FlowEditorSDK, FlowAPI } from '@wix/yoshi-flow-editor';
import {
  getRole,
  ProjectConfigureMainActionEditGfpp,
} from '../../../utils/manifestUtils';
import { ProjectDetailsWidgetComponentIds } from './constants';
import { PROJECT_PAGE_AUTO_FOCUS_TARGETS } from '../../../constants';

export const setConfigureConnectedComponents = ({
  controllerBuilder,
  editorSDK,
  flowAPI,
}: {
  controllerBuilder: WidgetBuilder;
  editorSDK: FlowEditorSDK;
  flowAPI: FlowAPI;
}) => {
  const { t } = flowAPI.translations;

  ProjectConfigureMainActionEditGfpp({
    controllerBuilder,
    editorSDK,
    flowAPI,
    role: getRole(ProjectDetailsWidgetComponentIds.Label),
    label: t('Project_Page_Connected_Component_Details_Label'),
    focusOn: PROJECT_PAGE_AUTO_FOCUS_TARGETS.DETAILS_LABEL,
  });

  ProjectConfigureMainActionEditGfpp({
    controllerBuilder,
    editorSDK,
    flowAPI,
    role: getRole(ProjectDetailsWidgetComponentIds.Text),
    label: t('Project_Page_Connected_Component_Details_Text'),
    focusOn: PROJECT_PAGE_AUTO_FOCUS_TARGETS.DETAILS_TEXT,
  });
};
