import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { WidgetBuilder, FlowEditorSDK, FlowAPI } from '@wix/yoshi-flow-editor';
import { customizeNativeGalleryGFPPs } from '../../utils/manifestUtils';
import { setConfigureConnectedComponents } from './config/utils';
import { ProjectWidgetComponentIds } from './config/constants';

export const getWidgetManifest: GetWidgetManifestFn = (
  controllerBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  flowAPI: FlowAPI,
) => {
  const { t } = flowAPI.translations;
  controllerBuilder.set({
    displayName: t('Widget_Name_Project_Widget'),
    nickname: 'projectWidget',
  });

  customizeNativeGalleryGFPPs({
    controllerBuilder,
    galleryRole: ProjectWidgetComponentIds.Gallery,
    t,
  });
  setConfigureConnectedComponents({ controllerBuilder, editorSDK, flowAPI });
};
