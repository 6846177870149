import { WidgetBuilder, FlowEditorSDK, FlowAPI } from '@wix/yoshi-flow-editor';
import {
  getRole,
  ProjectConfigureMainActionEditGfpp,
} from '../../../utils/manifestUtils';
import { ProjectWidgetComponentIds } from './constants';
import { PROJECT_PAGE_AUTO_FOCUS_TARGETS } from '../../../constants';

export const setConfigureConnectedComponents = ({
  controllerBuilder,
  editorSDK,
  flowAPI,
}: {
  controllerBuilder: WidgetBuilder;
  editorSDK: FlowEditorSDK;
  flowAPI: FlowAPI;
}) => {
  const { t } = flowAPI.translations;

  ProjectConfigureMainActionEditGfpp({
    controllerBuilder,
    editorSDK,
    flowAPI,
    role: getRole(ProjectWidgetComponentIds.Gallery),
    label: t('Project_Page_Connected_Component_Gallery'),
    focusOn: PROJECT_PAGE_AUTO_FOCUS_TARGETS.PROJECT_MEDIA,
  });
};
